import React from 'react';

import './styles.scss';

import {updateObject} from 'utils/reducerUtils';
import {authCallback} from 'utils/bankIdLogin';

import sendPin from 'api/sendPin';
import initCustomLogin from 'api/initCustomLogin';
import strings from 'utils/strings';

import queryString from 'query-string';
const parsedSearch = queryString.parse(window.top.location.search);
const key = parsedSearch.key;
class CustomLogin extends React.Component {
	constructor() {
		super();
		this.state = {
			inValidEmail: false,
			hasSendVerifcaiton: false,
			pinRecipientAddress: null,
			emailError: null,
			flowStatus: null,
			pswError: null,
			reqPin: null,
			testUser: {
				email: '',
				key: key ? key : null,
				pin: '',
				origin: 'screeningTest',
			},
		};
		this.handleUpdate = this.handleUpdate.bind(this);
		this.handleUpdateTestUserEvent =
			this.handleUpdateTestUserEvent.bind(this);
	}

	validateEmail() {
		const email = this.state.testUser.email;

		const value = email;
		if (/^\S+@\S+$$/.test(value)) {
			this.setState({
				inValidEmail: false,
			});
			if (!this.state.hasSendVerifcaiton) {
				this.sendPinToUserEmailAddress();
			}
		} else {
			this.setState({
				inValidEmail: true,
			});
		}
	}

	handleUpdateTestUserEvent(event) {
		const target = event.target;
		const key = target.name;
		const value = target.value;
		this.handleUpdate(key, value);
	}

	handleUpdate(key, value) {
		const {testUser} = this.state;
		this.setState({
			testUser: updateObject(testUser, {
				[key]: value,
			}),
		});
	}
	sendPinToUserEmailAddress() {
		sendPin(this.state.testUser)
			.then((res) => {
				this.setState({
					hasSendVerifcaiton: true,
					reqPin: res.PIN,
					flowStatus: null,
					pinRecipientAddress: res.email,
				});
			})
			.catch(() => {
				this.setState({
					flowStatus: 'Feil testkode eller epost',
				});
			});
	}
	initCustomLogin() {
		if (this.state.reqPin === this.state.testUser.pin) {
			initCustomLogin(this.state.testUser)
				.then((results) => {
					results.top = true;
					if (
						results &&
						results.action === 'auth' &&
						results.result === 'OK'
					) {
						window.removeEventListener('message', authCallback);
						localStorage.setItem(
							'authtoken-screening',
							results.authToken
						);

						window.top.location.href = `/test`;
					}
				})
				.catch(() => {
					this.setState({
						flowStatus: 'Ikke gyldig testkode',
					});
				});
		} else {
			this.setState({
				flowStatus: 'Feil pinkode',
			});
		}
	}

	render() {
		return (
			<div className="lit-customLogin">
				{!this.state.hasSendVerifcaiton && (
					<div className={'lit-customLogin-wrapper'}>
						<div className="lit-customPin-form-wrapper">
							{this.state.flowStatus && (
								<div className="lit-invalid-status-container">
									<p className="lit-invalid-note">
										{this.state.flowStatus}
									</p>
								</div>
							)}
							<div className="lit-epost-instrucitons">
								Skriv inn <b>registrert</b> e-post.
							</div>
							<form
								className="register-form"
								onSubmit={(event) => {
									event.preventDefault();
									this.validateEmail();
								}}
							>
								<div className="lit-customPin-form-wrapper">
									<div className="lit-customLogin-inner-content">
										<div className="lit-compound-fields">
											<div className="lit-label-usr">
												<label
													className="customLogin-user-lbl"
													htmlFor="user-email"
												>
													{strings.email}
												</label>
												<input
													required
													name="email"
													className="customLogin-user-tf"
													placeholder="skriv her"
													type="email"
													id="user-email"
													onChange={
														this
															.handleUpdateTestUserEvent
													}
												></input>
											</div>
										</div>
									</div>

									<div className="lit-btn-submit-wrapper">
										<button
											className="lit-btn-submit-pin"
											style={{color: 'white'}}
										>
											{'Logg Inn'}
										</button>
									</div>
								</div>
							</form>
						</div>
					</div>
				)}
				{this.state.hasSendVerifcaiton && (
					<div className="lit-customLogin-wrapper">
						<div className="lit-customPin-form-wrapper">
							{this.state.flowStatus && (
								<div className="lit-invalid-status-container">
									<p className="lit-invalid-note">
										{this.state.flowStatus}
									</p>
								</div>
							)}
							<div className="lit-pin-instrucitons">
								<span>
									<span>
										Vi har sendt epost med Pinkoden til{' '}
										<span style={{color: 'Lime'}}>
											<b>
												<em>
													{
														this.state
															.pinRecipientAddress
													}
												</em>
											</b>
										</span>
										.{' '}
									</span>
									Skriv inn <b>PINKODE</b> og start testen. Ta
									kontakt med læreren din hvis du ikke har
									fått den tilsendte pinkoden.{' '}
								</span>
							</div>
							<form
								className="register-form"
								onSubmit={(event) => {
									event.preventDefault();

									this.initCustomLogin();
								}}
							>
								<div className="lit-customPin-form-wrapper">
									<div className="lit-customPIn-inner-content">
										<div className="lit-compound-pin-fields">
											<div className="lit-label-pin">
												<label
													className="customLogin-pin-lbl"
													htmlFor="pin-kode"
												>
													PINKODE:{' '}
												</label>
												<input
													required
													name="pin"
													id="pin-kode"
													className="customLogin-pin-tf"
													onChange={
														this
															.handleUpdateTestUserEvent
													}
													type="text"
												></input>
											</div>
										</div>
									</div>
									<div className="lit-btn-submit-wrapper">
										<button
											className="lit-btn-submit"
											style={{color: 'white'}}
										>
											{'send'}
										</button>
									</div>
								</div>
							</form>
						</div>
					</div>
				)}
			</div>
		);
	}
}
export default CustomLogin;

/* eslint-disable vars-on-top */
import React from 'react';
import {connect} from 'react-redux';
import queryString from 'query-string';
import strings from '../../utils/strings';
import './styles.scss';
import Logo from 'assets/logo-dysmate.png';

import {initAuthAction} from 'actions/index';

import {BANK_ID, BANK_ID_MOBILE, FEIDE} from 'constants/index';
import CustomLogin from '../CustomLogin';
import getUserInfo from 'api/getUserInfo';
import getTestKeyInfo from 'api/getTestKeyInfo';
import CustomPinLogin from 'pages/CustomPinLogin';

const parsedSearch = queryString.parse(window.location.search);
const testKey = parsedSearch.key;
const authSuccess = parsedSearch.authSuccess;
function IsValidJSONString(str) {
	try {
		JSON.parse(str);
	} catch (e) {
		return false;
	}
	return true;
}
class Login extends React.Component {
	constructor() {
		super();

		this.state = {
			email: null,
			pinCode: null,
			userPin: null,
			isLoginVisible: false,
			passwordLogin: false,
			isFeide: false,
			instructions: null,
			requiresVerification: false,
			initialPin: null,
			dispatchAddress: null,
			testStatus: null,
			altError: null,
			pinLogin: true,
			isDistributed: false,
		};
	}

	componentDidMount() {
		if (!testKey) {
			window.location.href = `/?error=true`;
			return;
		}
		getUserInfo().then((response) => {
			if (response.ok) {
				window.location.href = `/test`;
			} else {
				this.setState({
					isLoginVisible: true,
				});
			}
		});
		getTestKeyInfo(testKey)
			.then((response) => {
				const email = response.email || response.dispatchAddress;
				const pinCode = response.initialPin;
				const requiresVerification = response.requiresVerification;
				const initialPin = response.initialPin;
				const dispatchAddress = response.dispatchAddress;
				const isDistributed = response.isDistributed;
				let instructionsText =
					response.status !== 'isCompleted' &&
					response.requiresVerification ? (
						<span>
							Denne testen er eldre enn en måned. For å sikre ditt
							personvern må vi bekrefte at det er riktig bruker
							starter testen. Vennligst bruk pinkoden som vi har
							sendt til{' '}
							<span style={{color: 'white'}}>
								<i>
									<u>{response.dispatchAddress}</u>
								</i>
							</span>{' '}
							for å starte testen.
						</span>
					) : null;
				if (response.status === 'isUsed') {
					instructionsText = (
						<span>
							Denne testen er startet før, men ikke fullført. For
							å sikre ditt personvern må vi bekrefte at det er
							riktig bruker starter testen. Vennligst bruk
							pinkoden som vi har sendt til{' '}
							<span style={{color: 'white'}}>
								<i>
									<u>{response.dispatchAddress}</u>
								</i>
							</span>{' '}
							for å starte testen.
						</span>
					);
				}
				const errorToThrow =
					response.status === 'isCompleted'
						? 'Denne testen er allerede fulført'
						: null;
				this.setState({
					email,
					pinCode,
					requiresVerification: false,
					pinLogin: instructionsText ? true : false,
					initialPin,
					isDistributed: Boolean(isDistributed),
					dispatchAddress,
					altError: errorToThrow,
					instructions: instructionsText,
				});
			})
			.catch((response) => {
				response.json().then((error) => {
					if (error.error === 'pincode instead') {
						this.setState({
							altError:
								'Det ser ut til at du bruker pinkode i stedet for testnøkkel for å starte testen.',
						});
					} else {
						this.setState({
							altError:
								'Testnøkkelen er ikke gyldig eller allerede brukt.',
						});
					}
				});
			});

		var receiveMessage = function (evt) {
			if (evt.data === 'MM:3PCunsupported') {
				/* document.getElementById(
						'start-fail-warn-ing'
					).innerHTML = `<img src=${warning} width="40px" height="30px">`; */
				document.getElementById('lit-site-warning').innerHTML =
					strings.warning2;
				document.getElementById('lit-site-warning_text').innerHTML =
					strings.warning2text;
				document.getElementById(
					'lit-site-settings-instructions'
				).innerHTML = strings.linkForInstructions;
				document.getElementById('lit-site-warning_text_end').innerHTML =
					strings.warning_text_end;
			} else if (evt.data === 'MM:3PCsupported') {
				document.getElementById('result').innerHTML = '';
			}
		};
		window.addEventListener('message', receiveMessage, false);

		if (authSuccess) {
			let data = atob(
				decodeURIComponent(
					window.location.search.substr(
						window.location.search.indexOf('=') + 1
					)
				)
			);

			if (data && IsValidJSONString(data)) {
				return new Promise((resolve, reject) => {
					data = JSON.parse(data);

					if (data.authError) {
						reject({message: 'There was an error logging you in'});
						return;
					}

					if (
						data &&
						data.action === 'auth' &&
						data.result === 'OK'
					) {
						localStorage.setItem(
							'authtoken-screening',
							data.authToken
						);

						window.location.href = `/test`;
						resolve();
						return;
					}
					reject({message: `Kunne ikke logge inn!`});
				});
			} else {
				return;
			}
		}
	}
	comparePinCodes() {
		if (
			this.state.initialPin.toLowerCase() ===
			this.state.userPin.toLowerCase()
		) {
			this.setState({
				requiresVerification: false,
				instructions: null,
			});
		} else {
			this.setState({
				altError: 'Feil pinkode. Testen kan ikke startes',
			});
		}
	}

	reAffirmTestKeyInfo(testKey) {
		getTestKeyInfo(testKey).then((response) => {
			const email = response.email || response.dispatchAddress;
			const pinCode = response.pinCode || this.state.initialPin;
			this.setState({
				email,
				pinCode,
				pinLogin: true,
				showCoockieDetectionFrame: false,
			});
		});
	}

	render() {
		const {isLoginVisible, isFeide, altError} = this.state;

		if (!isLoginVisible) {
			return null;
		}

		const {error, iframeUrl, initAuth, isInit} = this.props;
		const showIframe = isInit && iframeUrl;
		const showError = isInit && error;
		if (showIframe && isFeide) window.location.href = `${iframeUrl}`;
		return (
			<div>
				<header className="lit-header">
					<h1 className="lit-logo">
						<img
							className="lit-logo__img"
							src={Logo}
							alt="literate logo"
						/>
					</h1>
				</header>
				<div className="lit-wrapper--article text-center">
					<h2>Innlogging</h2>
					{!this.state.passwordLogin && !this.state.pinLogin && (
						<p>
							Du må logge inn før du kan starte testen. Velg om du
							vil logge inn med vanlig BankID, BankID på mobil,
							Epost, Feide eller pinkode.
						</p>
					)}
					{!this.state.requiresVerification &&
						!isInit &&
						!altError &&
						!this.state.passwordLogin &&
						!this.state.pinLogin && (
							<div className="bank-id-type-selector-wrapper">
								<button
									className="lit-btn lit-btn--small bg-secondary"
									onClick={() => {
										initAuth(BANK_ID, testKey);
									}}
								>
									BankID
								</button>
								<button
									className="lit-btn lit-btn--small bg-secondary"
									onClick={() => {
										this.setState({
											showCoockieDetectionFrame: true,
										});
										initAuth(BANK_ID_MOBILE, testKey);
									}}
								>
									BankID på Mobil
								</button>
								<button
									className="lit-btn lit-btn--small bg-secondary"
									onClick={() => {
										initAuth(FEIDE, testKey);
										this.setState({
											showCoockieDetectionFrame: true,
											isFeide: true,
										});
									}}
								>
									Feide
								</button>

								<button
									disabled={!this.state.isDistributed}
									className="lit-btn lit-btn--small bg-secondary"
									onClick={() => {
										this.setState({
											passwordLogin: true,
										});
									}}
								>
									Epost
								</button>

								<button
									className="lit-btn lit-btn--small bg-secondary"
									onClick={() => {
										this.reAffirmTestKeyInfo(testKey);
										this.setState({pinLogin: true});
									}}
								>
									Pinkode
								</button>
							</div>
						)}
					{altError && (
						<div>
							<br />
							<span
								className="error"
								style={{
									color: 'yellow',
									fontSize: '18px',
									overflowWrap: 'breakWord',
								}}
							>
								<span
									style={{
										fontWeight: 'bold',
										color: '#ff6600',
										textShadow: 'black 1px 1px 1px ',
										fontSize: '20px',
										marginRight: '3px',
										overflowWrap: 'break-word',
									}}
								>
									<i>{`Error: `}</i>
								</span>
								<span>{'	 '}</span>
								{altError}
							</span>
						</div>
					)}

					{!altError && this.state.instructions && (
						<div>
							<br />
							<span
								className="error"
								style={{
									color: 'yellow',
									fontSize: '18px',
									overflowWrap: 'breakWord',
								}}
							>
								<span
									style={{
										fontWeight: 'bold',
										color: '#ff6600',
										textShadow: 'black 1px 1px 1px ',
										fontSize: '20px',
										marginRight: '3px',
										overflowWrap: 'break-word',
									}}
								>
									<i>{` `}</i>
								</span>
								<span>{'	 '}</span>
								{this.state.instructions}
							</span>
							{/* 	<div className="lit-login-pin-field">
								<form
									className="register-form"
									onSubmit={(event) => {
										event.preventDefault();
										this.comparePinCodes();
									}}
								>
									<div className="lit-login-label-pin">
										<label
											className="lit-login-pin-lbl"
											htmlFor="pin-kode"
										>
											PINKODE:{' '}
										</label>
										<input
											required
											name="pin"
											id="pin-kode"
											className="lit-login-pin-tf"
											placeholder="skriv her"
											onChange={(e) => {
												this.setState({
													userPin: e.target.value,
												});
											}}
											type="text"
										/>
										<div className="lit-login-submit-wrapper">
											<button
												className="lit-login-submit"
												style={{color: 'white'}}
											>
												{'Send'}
											</button>
										</div>
									</div>
								</form>
							</div>
						 */}
						</div>
					)}

					<p className="lit-thirdPCsupport" id="result"></p>
					<div className="lit-thrid-party-settings">
						<p
							className="candidate-start-fail__instructions"
							style={{
								color: 'white',
								margin: 'auto',
								textAlign: 'center',
								fontSize: '18px',
							}}
						>
							<span
								id="start-fail-warn-ing"
								style={{justifyContent: 'center'}}
							></span>
							<span
								style={{
									color: 'LightSalmon',
									marginLeft: '2px',
								}}
							>
								<b>
									<span
										id="lit-site-warning"
										style={{marginLeft: '5px'}}
									></span>{' '}
								</b>
							</span>
							<em>
								<span id="lit-site-warning_text"></span>
							</em>
							<a
								target="_blank"
								rel="noopener noreferrer"
								href="https://www.dysmate.no/advarsel-2/"
							>
								<u style={{color: 'Yellow'}}>
									<span id="lit-site-settings-instructions"></span>
								</u>
							</a>
							<em>
								<span id="lit-site-warning_text_end"></span>
							</em>
						</p>
					</div>
					{showIframe && !isFeide && (
						<div className="bank-id-iframe-wrapper">
							<iframe
								title="login-frame"
								frameBorder="0"
								id="authFrame"
								scrolling="no"
								src={iframeUrl}
								style={{
									height: '600px',
									width: '100%',
								}}
							/>
						</div>
					)}
					{this.state.passwordLogin && (
						<div className="lit-customlogin-sub-wrapper">
							<CustomLogin />
						</div>
					)}

					{this.state.pinLogin && this.state.email && (
						<div className="lit-customlogin-sub-wrapper">
							<CustomPinLogin
								email={this.state.email}
								pinCode={this.state.pinCode}
							/>
						</div>
					)}
					{showError && <div>{error}</div>}
					{this.state.showCoockieDetectionFrame && (
						<div>
							<iframe
								src="https://mindmup.github.io/3rdpartycookiecheck/start.html"
								style={{display: 'none'}}
							/>
						</div>
					)}
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	const {error, iframeUrl, isInit} = state.auth;

	return {
		error,
		iframeUrl,
		isInit,
	};
};

const mapDispatchToProps = {
	initAuth: initAuthAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);

import {SET_TEST_STEP_ANSWERS, SET_SENDING_TEST_RESULTS} from 'actions/index';

import {updateObject} from 'utils/reducerUtils';

import {TEST_STEPS} from 'constants/index';

const defaultTestStepAnswers = {
	[TEST_STEPS.ORDDIKTAT]: {},
	[TEST_STEPS.ORDKJEDETESTEN]: {},
	[TEST_STEPS.TULLEORDTESTEN]: {},
	[TEST_STEPS.SKRIVEHASTIGHET]: {},
	[TEST_STEPS.LESEPRØVEN]: {},
	[TEST_STEPS.ORDFORSTÅELSE]: {},
	isSendingTestResults: false,
};

const setTestStepAnswers = (state, action) => {
	if (!state.hasOwnProperty(action.step)) {
		throw new Error(`Invalid test step: ${action.step}`);
	}

	return updateObject(state, {
		[action.step]: action.answers,
		isSendingTestResults: true,
	});
};

const SetIsSendingTestResultsToFalse = (state, action) => {
	return updateObject(state, {
		isSendingTestResults: action.isSendingTestResults,
	});
};

const testStepAnswers = (state = defaultTestStepAnswers, action) => {
	switch (action.type) {
		case SET_TEST_STEP_ANSWERS:
			return setTestStepAnswers(state, action);
		case SET_SENDING_TEST_RESULTS:
			return SetIsSendingTestResultsToFalse(state, action);

		default:
			return state;
	}
};

export default testStepAnswers;

import React from 'react';
import {connect} from 'react-redux';
import classNames from 'classnames';
import strings from 'utils/strings';

import {updateObject} from 'utils/reducerUtils';

import Countdown from 'components/Countdown';

import noData from './languages/no-data.json';
import nnData from './languages/nn-data.json';

const tutorialObj = {
	no: noData,
	nn: nnData,
};

class Test extends React.Component {
	constructor() {
		super();

		const results = {};
		this.assignments = tutorialObj[strings.getLanguage()];

		for (const assignment of this.assignments) {
			results[assignment.id] = '';
		}
		this.state = {
			results,
			timeRanOut: false,
		};

		this.audioRefs = {};
	}

	setResults(id, value) {
		const {results} = this.state;

		this.setState({
			results: updateObject(results, {
				[id]: value,
			}),
		});
	}

	render() {
		const {onNextStep, isSendingTestResults} = this.props;

		const {results, timeRanOut} = this.state;

		const allResultsHasValues = Object.keys(results).every(
			(key) => results[key]
		);
		const isNextButtonVisible = allResultsHasValues || timeRanOut;

		let filteredAssignments;

		if (timeRanOut) {
			filteredAssignments = this.assignments.filter((assignment) => {
				return results[assignment.id];
			});
		} else {
			filteredAssignments = this.assignments;
		}

		return (
			<div className="lit-test">
				<div className="lit-infobar">
					<Countdown
						duration={120}
						onComplete={() => {
							this.setState({
								timeRanOut: true,
							});
						}}
					/>
				</div>
				<p>{strings.tulleordInstruksjon}</p>
				<div className="lit-tulleord">
					{filteredAssignments.map((assignment) => {
						const {id, value} = assignment;

						const words = value.split(' ');

						const currentAssignmentValue = results[id];

						return (
							<div className="lit-words" key={id}>
								<span className="lit-input__label lit-input__label--count">
									{id}
								</span>
								<div className="lit-word-list">
									{words.map((word) => {
										const idWord = id + word;
										return (
											<span
												className={classNames({
													'lit-word': true,
													disabled: timeRanOut,
												})}
												key={idWord}
											>
												<input
													checked={
														currentAssignmentValue ===
														word
													}
													className="lit-word__field"
													disabled={timeRanOut}
													id={idWord}
													onChange={() =>
														this.setResults(
															id,
															word
														)
													}
													type="radio"
												/>
												<label
													className={classNames({
														'lit-word__label': true,
														disabled: timeRanOut,
													})}
													htmlFor={idWord}
												>
													<div className="checkbox" />
													{word}
												</label>
											</span>
										);
									})}
								</div>
							</div>
						);
					})}
				</div>

				{isNextButtonVisible && (
					<button
						className="lit-btn bg-primary"
						onClick={() => onNextStep(results)}
						disabled={isSendingTestResults}
					>
						{strings.done}
					</button>
				)}
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	const {
		testStepAnswers: {isSendingTestResults},
	} = state;

	return {
		isSendingTestResults,
	};
};

export default connect(mapStateToProps)(Test);

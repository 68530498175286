import audio1 from 'assets/sounds/nn/1 01 kinobillett.mp3';
import audio2 from 'assets/sounds/nn/1 02 her.mp3';
import audio3 from 'assets/sounds/nn/1 03 meteorolog.mp3';
import audio4 from 'assets/sounds/nn/1 04 hvete.mp3';
import audio5 from 'assets/sounds/nn/1 05 gjerde.mp3';
import audio6 from 'assets/sounds/nn/1 06 nysgjerrig.mp3';
import audio7 from 'assets/sounds/nn/1 07 profesjonelt.mp3';
import audio8 from 'assets/sounds/nn/1 08 herverk.mp3';
import audio9 from 'assets/sounds/nn/1 09 skjelven.mp3';
import audio10 from 'assets/sounds/nn/1 10 sjenkestopp.mp3';
import audio11 from 'assets/sounds/nn/1 11 fylt.mp3';
import audio12 from 'assets/sounds/nn/1 12 sjal.mp3';
import audio13 from 'assets/sounds/nn/1 13 vært.mp3';
import audio14 from 'assets/sounds/nn/1 14 godt.mp3';
import audio15 from 'assets/sounds/nn/1 15 ogsa.mp3';
import audio16 from 'assets/sounds/nn/1 16 vert.mp3';
import audio17 from 'assets/sounds/nn/1 17 gjerne.mp3';
import audio18 from 'assets/sounds/nn/1 18 kjerring.mp3';
import audio19 from 'assets/sounds/nn/1 19 hjerne.mp3';
import audio20 from 'assets/sounds/nn/1 20 trygt.mp3';
import audio21 from 'assets/sounds/nn/1 21 gatt.mp3';
import audio22 from 'assets/sounds/nn/1 22 love.mp3';
import audio23 from 'assets/sounds/nn/1 23 hvert.mp3';
import audio24 from 'assets/sounds/nn/1 24 noklene.mp3';
import audio25 from 'assets/sounds/nn/1 25 dysleksi.mp3';
import audio26 from 'assets/sounds/nn/1 26 lave.mp3';
import audio27 from 'assets/sounds/nn/1 27 hardt.mp3';
import audio28 from 'assets/sounds/nn/1 28 utviklingsmuligheter.mp3';
import audio29 from 'assets/sounds/nn/1 29 visste.mp3';
import audio30 from 'assets/sounds/nn/1 30 skøyter.mp3';
import audio31 from 'assets/sounds/nn/1 31 dessverre.mp3';
import audio32 from 'assets/sounds/nn/1 32 drømte.mp3';
import audio33 from 'assets/sounds/nn/1 33 oss.mp3';
import audio34 from 'assets/sounds/nn/1 34 krigsskip.mp3';
import audio35 from 'assets/sounds/nn/1 35 hvis.mp3';
import audio36 from 'assets/sounds/nn/1 36 sove.mp3';
import audio37 from 'assets/sounds/nn/1 37 interessant.mp3';
import audio38 from 'assets/sounds/nn/1 38 verdt.mp3';
import audio39 from 'assets/sounds/nn/1 39 viste.mp3';
import audio40 from 'assets/sounds/nn/1 40 selvsagt.mp3';
import audio41 from 'assets/sounds/nn/1 41 fullt.mp3';
import audio42 from 'assets/sounds/nn/1 42 hos.mp3';
import audio43 from 'assets/sounds/nn/1 43 aldri.mp3';
import audio44 from 'assets/sounds/nn/1 44 kanskje.mp3';
import audio45 from 'assets/sounds/nn/1 45 sendt.mp3';

const audioFiles = {
	1: audio1,
	2: audio2,
	3: audio3,
	4: audio4,
	5: audio5,
	6: audio6,
	7: audio7,
	8: audio8,
	9: audio9,
	10: audio10,
	11: audio11,
	12: audio12,
	13: audio13,
	14: audio14,
	15: audio15,
	16: audio16,
	17: audio17,
	18: audio18,
	19: audio19,
	20: audio20,
	21: audio21,
	22: audio22,
	23: audio23,
	24: audio24,
	25: audio25,
	26: audio26,
	27: audio27,
	28: audio28,
	29: audio29,
	30: audio30,
	31: audio31,
	32: audio32,
	33: audio33,
	34: audio34,
	35: audio35,
	36: audio36,
	37: audio37,
	38: audio38,
	39: audio39,
	40: audio40,
	41: audio41,
	42: audio42,
	43: audio43,
	44: audio44,
	45: audio45,
};

export default audioFiles;

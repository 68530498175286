import modilSound from 'assets/sounds/nn/3 04 Tulleord Modil er feil.mp3';
import falkSound from 'assets/sounds/nn/3 05 Tulleord Falk er riktig.mp3';
import vuksSound from 'assets/sounds/nn/3 05 Tulleord Vuks er feil.mp3';
import flyntSound from 'assets/sounds/nn/3 06 Tulleord Flynt er feil.mp3';
import gjalteSound from 'assets/sounds/nn/3 07 Tulleord Gjalte er feil.mp3';

import vorSound from 'assets/sounds/nn/3 09 Tulleord Vor er riktig.mp3';
import blastellSound from 'assets/sounds/nn/3 10 Tulleord Blastell er feil.mp3';
import peanoSound from 'assets/sounds/nn/3 11 Tulleord Peano er feil.mp3';
import brillterSound from 'assets/sounds/nn/3 12 Tulleord Brillter er feil.mp3';
import knainSound from 'assets/sounds/nn/3 12 Tulleord Knain er feil.mp3';

import example from 'assets/sounds/nn/3 02 Tulleord Sgo.mp3';
import dinTur from 'assets/sounds/nn/3 03 Tulleord Na er det din tur.mp3';
import starterTesten from 'assets/sounds/nn/3 13 Tulleord Starter testen.mp3';

export const exampleSound = example;
export const dinTurSound = dinTur;
export const starterTestenSound = starterTesten;

export const tutorialAssignment = {
	id: 1,
	choices: [
		{
			word: 'omkel',
		},
		{
			word: 'helefopter',
		},
		{
			word: 'øsk',
		},
		{
			isCorrect: true,
			word: 'sgo',
		},
		{
			word: 'drapt',
		},
	],
};

export const assignments = [
	{
		id: 2,
		choices: [
			{
				word: 'modil',
				comment:
					'Modil er feil, fordi vi seier ikkje modil - men mobil. Ordet modil finst ikkje på norsk, og det betyr ingen ting.',
				sound: modilSound,
			},
			{
				word: 'vuks',
				comment:
					'Vuks er feil, fordi vi seier ikkje vuks - men voks. Ordet vuks finst ikkje på norsk, og det betyr ingen ting.',
				sound: vuksSound,
			},
			{
				word: 'fålk',
				comment:
					'Fålk er heilt riktig. Vi skriv ikkje ordet slik, men vi uttalar det slik.',
				sound: falkSound,
			},
			{
				word: 'flynt',
				comment:
					'Flynt er feil. Dette ordet finst ikkje på norsk, og det betyr ingen ting.',
				sound: flyntSound,
			},
			{
				word: 'gjalte',
				comment:
					'Gjalte er feil. Dette ordet finst ikkje på norsk, og det betyr ingen ting.',
				sound: gjalteSound,
			},
		],
	},
	{
		id: 3,
		choices: [
			{
				word: 'kjåsk',
				comment:
					'Kjåsk er riktig, fordi det er ein liten bygning der det vert selt avisar. Vi skriv ikkje ordet slik, men uttalar det slik.',
				sound: vorSound,
			},
			{
				word: 'blastell',
				comment:
					'Blastell er feil. Dette ordet finst ikkje på norsk, og det betyr ingen ting.',
				sound: blastellSound,
			},
			{
				word: 'peano',
				comment:
					'Peano er feil, fordi vi seier ikkje peano - men piano. Ordet peano finst ikkje på norsk, og det betyr ingen ting.',
				sound: peanoSound,
			},
			{
				word: 'knain',
				comment:
					'Knain er feil. Dette ordet fint ikkje på norsk, og det betyr ingen ting.',
				sound: knainSound,
			},
			{
				word: 'brillter',
				comment:
					'Brillter er feil, fordi vi seier ikkje brillter - men briller. Ordet brillter finst ikkje på norsk, og det betyr ingen ting.',
				sound: brillterSound,
			},
		],
	},
];

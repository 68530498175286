export const DEVELOPMENT = 'development';
export const PRODUCTION = 'production';
export const TESTING = 'testing';
const validEnvironments = [DEVELOPMENT, TESTING, PRODUCTION];

const CURRENT_ENVIRONMENT = PRODUCTION; //process.env.NODE_ENV

export const ENVIRONMENT = validEnvironments.includes(CURRENT_ENVIRONMENT)
	? CURRENT_ENVIRONMENT
	: PRODUCTION; //Default to production, in case config is missing

export const IS_IN_PRODUCTION = ENVIRONMENT !== DEVELOPMENT;

if (!validEnvironments.includes(ENVIRONMENT)) {
	console.warn('MISSING ENVIRONMENT SELECTOR');
}

const endpointUrls = {
	development: 'http://localhost:3000',
	testing: 'http://lit-testing.eu-central-1.elasticbeanstalk.com',
	production: 'https://screeningtest.literate.no',
};

export const ENDPOINT_URL = endpointUrls[ENVIRONMENT];

export const BANK_ID = 'BankID';
export const BANK_ID_MOBILE = 'BankIDMobile';
export const FEIDE = 'feide';

const ORDDIKTAT = 'ORDDIKTAT';
const ORDKJEDETESTEN = 'ORDKJEDETESTEN';
const TULLEORDTESTEN = 'TULLEORDTESTEN';
const SKRIVEHASTIGHET = 'SKRIVEHASTIGHET';
const LESEPRØVEN = 'LESEPRØVEN';
const ORDFORSTÅELSE = 'ORDFORSTÅELSE';

export const TEST_STEPS = {
	ORDDIKTAT,
	ORDKJEDETESTEN,
	TULLEORDTESTEN,
	SKRIVEHASTIGHET,
	LESEPRØVEN,
	ORDFORSTÅELSE,
};

export const TEST_STEP_IDS = {
	[ORDDIKTAT]: 1,
	[ORDKJEDETESTEN]: 2,
	[TULLEORDTESTEN]: 3,
	[SKRIVEHASTIGHET]: 4,
	[LESEPRØVEN]: 5,
	[ORDFORSTÅELSE]: 6,
};

export const DASHARRAY_LENGTH = 230;

export const UNAUTHORIZED = 401;
export const WRONGKEY = 422;

export const REDUCED_TIMERS = false;

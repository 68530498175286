import React from 'react';
import {connect} from 'react-redux';
import strings from 'utils/strings';
import './styles.scss';

import {
	goToNextStep,
	setTestStepAnswers,
	setIsSendingTestResultsToFalse,
} from 'actions/index';

import {TEST_STEPS} from 'constants/index';

import Intro from './Intro';
import Tutorial from './Tutorial';
import Test from './Test';

const subSteps = [Intro, Tutorial, Test];

class Tulleordtesten extends React.Component {
	constructor() {
		super();

		this.handleNextSubstep = this.handleNextSubstep.bind(this);

		this.state = {
			currentSubstepIndex: 0,
		};
	}

	handleNextSubstep(results) {
		const {currentSubstepIndex} = this.state;

		const {
			onGoToNextStep,
			onSetTestStepAnswers,
			onSetIsSendingTestResultsToFalse,
		} = this.props;

		const isLastStep = currentSubstepIndex === subSteps.length - 1;

		if (isLastStep) {
			const resultKeys = Object.keys(results);
			const formattedResults = resultKeys.map((id) => {
				const value = results[id];
				return {
					id,
					value,
				};
			});
			onSetTestStepAnswers(
				TEST_STEPS.TULLEORDTESTEN,
				formattedResults
			).then(() => {
				onSetIsSendingTestResultsToFalse();
				onGoToNextStep();
			});
		} else {
			this.setState({
				currentSubstepIndex: currentSubstepIndex + 1,
			});
			setTimeout(() => {
				window.scrollTo(0, 0);
			});
		}
	}

	render() {
		const {currentSubstepIndex} = this.state;

		const CurrentSubStep = subSteps[currentSubstepIndex];

		return (
			<div className="lit-intro">
				<h1 className="lit-page-title">{strings.tulleordtesten}</h1>
				<CurrentSubStep onNextStep={this.handleNextSubstep} />
			</div>
		);
	}
}

const mapStateToProps = () => {
	return {};
};

const mapDispatchToProps = {
	onGoToNextStep: goToNextStep,
	onSetTestStepAnswers: setTestStepAnswers,
	onSetIsSendingTestResultsToFalse: setIsSendingTestResultsToFalse,
};

export default connect(mapStateToProps, mapDispatchToProps)(Tulleordtesten);

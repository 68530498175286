import {combineReducers,} from 'redux';

import auth from './auth';
import testStepAnswers from './testStepAnswers';
import currentStepIndex from './currentStepIndex';

const rootReducer = combineReducers({
	auth,
	testStepAnswers,
	currentStepIndex,
});

export default rootReducer;

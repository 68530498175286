import React from 'react';
import {connect} from 'react-redux';
import strings from 'utils/strings';

// import './styles.scss';
import AudioPlayer from 'components/AudioPlayer';
import previewVideo from './assets/bf21ee40d6ffb3fb524a0c16e2b81130.mp4';

import dataNo from './languages/no-data.json';
import dataNn from './languages/nn-data.json';
import audioNo from 'assets/sounds/no/4 1 Skrivehastighet Intro.mp3';
import audioNn from 'assets/sounds/nn/4 1 Skrivehastighet Intro.mp3';

const dataObj = {
	no: {data: dataNo, audio: audioNo},
	nn: {data: dataNn, audio: audioNn},
};

class Intro extends React.Component {
	constructor() {
		super();

		const assets = dataObj[strings.getLanguage()];

		this.audio = assets.audio;
		this.text = assets.data[0].value;
	}

	render() {
		const {onNextStep} = this.props;

		let videoRef;

		return (
			<div className="">
				<p className="text-left">
					<AudioPlayer
						autoplay
						inline
						onPause={() => {
							videoRef.play();
						}}
						src={this.audio}
					/>
					{strings.skrivehastighetIntro}
				</p>
				<div className="tutorial-text">
					<h3 className="text">
						<strong>{this.text}</strong>
					</h3>
				</div>
				<p>{strings.skrivehastighetIntro2}</p>

				<div className="lit-preview">
					<p className="lit-preview__label">
						{strings.testLooksLikeThis}
					</p>
					<video
						className="lit-preview__video"
						loop
						onClick={() => {
							videoRef.play();
						}}
						ref={ref => {
							videoRef = ref;
						}}
						src={previewVideo}
					/>
				</div>

				<p>{strings.skrivehastighetHint}</p>

				<button
					className="lit-btn bg-primary"
					onClick={() => onNextStep()}
				>
					{strings.start}
				</button>
			</div>
		);
	}
}

const mapStateToProps = () => {
	return {};
};

export default connect(mapStateToProps)(Intro);

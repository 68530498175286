//TODO-SIMEN REPLACE ALL THESE WITH LOCALIZED SOUNDS
import modilSound from 'assets/sounds/no/3 04 Tulleord Modil er feil.mp3';
import falkSound from 'assets/sounds/no/3 05 Tulleord Falk er riktig.mp3';
import vuksSound from 'assets/sounds/no/3 05 Tulleord Vuks er feil.mp3';
import flyntSound from 'assets/sounds/no/3 06 Tulleord Flynt er feil.mp3';
import gjalteSound from 'assets/sounds/no/3 07 Tulleord Gjalte er feil.mp3';

import vorSound from 'assets/sounds/no/3 09 Tulleord Vor er riktig.mp3';
import blastellSound from 'assets/sounds/no/3 10 Tulleord Blastell er feil.mp3';
import peanoSound from 'assets/sounds/no/3 11 Tulleord Peano er feil.mp3';
import brillterSound from 'assets/sounds/no/3 12 Tulleord Brillter er feil.mp3';
import knainSound from 'assets/sounds/no/3 12 Tulleord Knain er feil.mp3';

import example from 'assets/sounds/no/3 02 Tulleord Sgo.mp3';
import dinTur from 'assets/sounds/no/3 03 Tulleord Na er det din tur.mp3';
import starterTesten from 'assets/sounds/no/3 13 Tulleord Starter testen.mp3';

export const exampleSound = example;
export const dinTurSound = dinTur;
export const starterTestenSound = starterTesten;

export const tutorialAssignment = {
	id: 1,
	choices: [
		{
			word: 'omkel',
		},
		{
			word: 'helefopter',
		},
		{
			word: 'øsk',
		},
		{
			isCorrect: true,
			word: 'sgo',
		},
		{
			word: 'drapt',
		},
	],
};

export const assignments = [
	{
		id: 2,
		choices: [
			{
				word: 'modil',
				comment:
					'Modil er feil, fordi vi sier ikke modil – men mobil. Ordet modil finnes ikke på norsk, og det betyr ingen ting.',
				sound: modilSound,
			},
			{
				word: 'vuks',
				comment:
					'Vuks er feil, fordi vi sier ikke vuks – men voks. Ordet vuks finnes ikke på norsk, og det betyr ingen ting.',
				sound: vuksSound,
			},
			{
				word: 'fålk',
				comment:
					'Fålk er helt riktig. Vi skriver ikke ordet slik, men vi uttaler det slik.',
				sound: falkSound,
			},
			{
				word: 'flynt',
				comment:
					'Flynt er feil. Dette ordet finnes ikke på norsk, og det betyr ingen ting.',
				sound: flyntSound,
			},
			{
				word: 'gjalte',
				comment:
					'Gjalte er feil. Dette ordet finnes ikke på norsk, og det betyr ingen ting.',
				sound: gjalteSound,
			},
		],
	},
	{
		id: 3,
		choices: [
			{
				word: 'vorr',
				comment:
					'Vorr er riktig, fordi det er et spørreord som vi bruker når vi for eksempel lurer på hvor noen befinner seg. Vi skriver ikke ordet slik, men uttaler det slik.',
				sound: vorSound,
			},
			{
				word: 'blastell',
				comment:
					'Blastell er feil. Dette ordet finnes ikke på norsk, og det betyr ingen ting.',
				sound: blastellSound,
			},
			{
				word: 'peano',
				comment:
					'Peano er feil, fordi vi sier ikke peano – men piano. Ordet peano finnes ikke på norsk, og det betyr ingen ting.',
				sound: peanoSound,
			},
			{
				word: 'knain',
				comment:
					'Knain er feil. Dette ordet finnes ikke på norsk, og det betyr ingen ting.',
				sound: knainSound,
			},
			{
				word: 'brillter',
				comment:
					'Brillter er feil, fordi vi sier ikke brillter – men briller. Ordet brillter finnes ikke på norsk, og det betyr ingen ting.',
				sound: brillterSound,
			},
		],
	},
];

import React from 'react';
import {connect} from 'react-redux';
import classNames from 'classnames';

import {updateObject} from 'utils/reducerUtils';

import AudioPlayer from 'components/AudioPlayer';

import audioFilesNo from './languages/no-audioFiles';
import audioFilesNn from './languages/nn-audioFiles';
import assignments from './languages/data.json';
import strings from 'utils/strings';

const audioFilesObj = {
	no: audioFilesNo,
	nn: audioFilesNn,
};

const maxAudioPlayCount = 2;

class Test extends React.Component {
	constructor() {
		super();

		this.audioFiles = audioFilesObj[strings.getLanguage()];

		const results = {};

		for (const assignment of assignments) {
			results[assignment.id] = assignment.value;
		}

		this.state = {
			results,
			disabledAssignments: {},
			audioPlayCounts: {},
		};
	}

	setResults(id, value) {
		const {results} = this.state;

		this.setState({
			results: updateObject(results, {
				[id]: value,
			}),
		});
	}

	getDisabledAssignments() {
		const {results} = this.state;

		const disabledAssignments = {};
		for (let i = 0; i < assignments.length; i++) {
			const assignmentId = assignments[i].id;
			if (results[assignmentId]) {
				disabledAssignments[assignmentId] = true;
			}
		}
		return disabledAssignments;
	}

	render() {
		const {onNextStep, refs, isSendingTestResults} = this.props;

		const {audioPlayCounts, disabledAssignments, results} = this.state;

		const allResultsHasValues = Object.keys(results).every(
			(key) => results[key]
		);
		const isNextButtonVisible = allResultsHasValues;

		return (
			<div className="lit-wrapper--thin lit-test lit-diktat">
				{assignments.map((assignment) => {
					const assignmentId = assignment.id;
					const audioFile = this.audioFiles[assignmentId];
					const assignmentAudioPlayCount =
						audioPlayCounts[assignmentId] || 0;
					const isDisabled = disabledAssignments[assignmentId];
					const isAudioDisabled =
						isDisabled ||
						assignmentAudioPlayCount === maxAudioPlayCount;

					let audioPlayerRef;
					return (
						<div
							className={classNames('lit-input lit-revealed', {
								'lit-input--disabled': isDisabled,
							})}
							key={assignmentId}
						>
							<AudioPlayer
								disabled={isAudioDisabled}
								id={assignmentId}
								onStart={() => {
									this.setState({
										audioPlayCounts: updateObject(
											audioPlayCounts,
											{
												[assignmentId]:
													assignmentAudioPlayCount +
													1,
											}
										),
									});
								}}
								ref={(ref) => {
									audioPlayerRef = ref;
								}}
								refs={refs}
								src={audioFile}
							/>
							<input
								className="lit-input__field"
								disabled={isDisabled}
								onChange={(ev) => {
									if (isDisabled) {
										return;
									}
									const value = ev.target.value;
									this.setResults(assignmentId, value);
								}}
								onFocus={() => {
									const disabledAssignments =
										this.getDisabledAssignments();
									disabledAssignments[assignmentId] = false;

									if (assignmentAudioPlayCount === 0) {
										audioPlayerRef.handleStartPlayer();
									}

									this.setState({
										disabledAssignments,
									});
								}}
								placeholder={strings.writeWordHere}
								spellCheck={false}
								type="text"
							/>
						</div>
					);
				})}
				{isNextButtonVisible && (
					<button
						className="lit-btn lit bg-primary"
						onClick={() => onNextStep(results)}
						disabled={isSendingTestResults}
					>
						{strings.done}
					</button>
				)}
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	const {
		testStepAnswers: {isSendingTestResults},
	} = state;

	return {
		isSendingTestResults,
	};
};

export default connect(mapStateToProps)(Test);

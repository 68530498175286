import {IS_IN_PRODUCTION} from 'constants/index';
import LocalizedStrings from 'react-localization';
import no from 'languages/no';
import nn from 'languages/nn';

const strings = new LocalizedStrings({
	no: no,
	nn: nn,
});

if (!IS_IN_PRODUCTION) {
	const mainLanguage = no;
	const mainLanguageKeys = Object.keys(mainLanguage);
	const otherLanguages = [nn];

	for (let i = 0; i < otherLanguages.length; i++) {
		const language = otherLanguages[i];
		const languageKeys = Object.keys(language);

		for (const mainLanguageKey of mainLanguageKeys) {
			if (languageKeys.indexOf(mainLanguageKey) === -1) {
				console.warn(
					`${mainLanguageKey} missing in otherLanguage with index: ${i}`
				);
			}
		}
	}
}

export default strings;
